import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { 
	MatSidenavModule,
	MatToolbarModule,
	MatMenuModule,
	MatListModule,
	MatButtonModule,
	MatIconModule,
  MatCheckboxModule,
  MatTableModule,
  MatCardModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDialogModule,
  MatSliderModule,
  MatChipsModule,
  MatSelectModule
} from '@angular/material';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatTableModule,
    MatCardModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatSliderModule,
    MatChipsModule,
    MatSelectModule
  ],
  exports: [
  	MatSidenavModule,
  	MatToolbarModule,
  	MatMenuModule,
  	MatListModule,
  	MatButtonModule,
  	MatIconModule,
    MatCheckboxModule,
    MatTableModule,
    MatCardModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatSliderModule,
    MatChipsModule,
    MatSelectModule
  ]
})
export class MaterialModule { }
