import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{ path: '', redirectTo: 'frontend', pathMatch: 'full'},
	{
    	path: 'frontend',
    	loadChildren: './modules/frontend/frontend.module#FrontendModule'
  	},
  	{
    	path: 'coach',
    	loadChildren: './modules/coach/coach.module#CoachModule'
  	},
    {
      path: 'student',
      loadChildren: './modules/student/student.module#StudentModule'
    },
    {
      path: 'accounts',
      loadChildren: './modules/accounts/accounts.module#AccountsModule'
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
